import React from 'react';

const NavbarTop = () => {
  return (
    <>
      <div className="navbar__top">
        <div className="container-fluid">
          <div className="navbar__top-wrapper">
            <button
              className="navbar-toggler mobil-nav-toggler"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar"
              aria-label="Toggle navigation"
            >
              <img src="/assets/images/menu.png" alt="" />
            </button>
            <div className="navbar__top-welcome">
              <span className="navbar__top-user">Asferrari Owner</span>
              <p className="navbar__top-message">
                <span className="text-highlight">Welcome Back</span>, 1 October 2023
                | 11:59 AM GMT
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )

}
export default NavbarTop;