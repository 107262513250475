import React, {useEffect, useRef, useState} from 'react';
import NavbarTop from "./NavbarTop";
import {deleteProduct, getServiceById, updateService} from "../api/axios";
import {useParams} from "react-router-dom";

const ServiceUpdate = () => {
  const {id} = useParams();

  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [image, setImage] = useState(null);
  const [imagePrev, setImagePrev] = useState(null);
  const inputFile = useRef(null);

  useEffect(() => {
    getServiceById(id).then(value => {
      console.log(value)
      setTitle(value?.data?.data?.title);
      setText(value?.data?.data?.text);
      setImagePrev("data:image/jpeg;base64," + value?.data?.data?.servicePicture);
    })
  }, [0]);

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(event.target.files[0]);
      setImagePrev(URL.createObjectURL(event.target.files[0]));
    }
  }

  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  async function onSubmit(e) {
    try {
      let state = {
        title: title,
        text: text,
      }

      const model = JSON.stringify(state);

      let formData = new FormData();
      formData.append('file', image);
      formData.append('model', model);

      await updateService(formData, id);
      alert("Saved")
      window.location.reload();
    } catch (error) {
      console.log(error);
      alert(error?.response?.data?.validationErrors)
    }
  }

  return (<>
    <NavbarTop/>
    <div className="container" style={{marginTop: "20px"}}>
      <div className="brands-create">
        <div className="settings__content">
          <span className="settings__headline">
            Update Service
          </span>
          <div className="settings__wrapper website__wrapper">
            <input
              value={title}
              onChange={e => setTitle(e.target.value)}
              className="settings__input"
              type="text"
              placeholder="Title"
              required
            />
            <textarea
              value={text}
              style={{height: "5rem"}}
              maxLength={8000}
              onChange={e => setText(e.target.value)}
              className="settings__input"
              placeholder="Content"
              required
            />
            <div className="d-flex flex-row gap-5">
              <div className="d-flex flex-column w-50" style={{gap: "2.5rem"}}>
                <label htmlFor="images" className="drop-container" id="dropcontainer">
                  <button className="upload-image-button" onClick={onButtonClick}>Add Service Image</button>
                </label>
                <input onChange={onImageChange} className="align-rigfht" ref={inputFile} style={{display: 'none'}} type='file' id='file'/>
              </div>

              <div className="d-flex justify-content-center">
                {imagePrev !== null ? <img className="w-100" style={{maxHeight: "250px"}} alt="preview image" src={imagePrev}/> : ""}
              </div>
            </div>
            <button onClick={onSubmit}
                    type="submit"
                    className="btn btn-primary btn-brand-save"
                    disabled={title.trim() === "" || text.trim() === ""}>
              Update Service
            </button>
          </div>
        </div>
      </div>
    </div>
  </>)

}
export default ServiceUpdate;