import {useNavigate} from "react-router-dom";
import useLogout from "../hooks/useLogout";
import NavbarTop from "./NavbarTop";
import React, {useEffect, useState} from "react";
import {createTuning, getBrands, getModelsByBrandName} from "../api/axios";
import Select from "react-select";

const Home = () => {
  const navigate = useNavigate();
  const logout = useLogout();

  const [year, setYear] = useState("");
  const [price, setPrice] = useState("");
  const [hpOriginal, setHpOriginal] = useState("");
  const [motor, setMotor] = useState("");
  const [hpAfterTuning, setHpAfterTuning] = useState("");
  const [hpDifference, setHpDifference] = useState("");
  const [torqueOriginal, setTorqueOriginal] = useState("");
  const [torqueAfterTuning, setTorqueAfterTuning] = useState("");
  const [torqueDifference, setTorqueDifference] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedModelOption, setSelectedModelOption] = useState("");

  const [selectOptions, setSelectOptions] = useState([]);
  const [selectModelOptions, setSelectModelOptions] = useState([]);

  useEffect(async () => {
    let brandsMap = [];
    await getBrands().then(value => {
      value?.data?.data.forEach(item => {
        brandsMap.push({value: item?.brandName, label: item?.brandName})
      })
    })
    setSelectOptions(brandsMap);
  }, [0]);

  async function onSubmit(e) {
    e.preventDefault();
    try {
      await createTuning({
        'brand': selectedOption,
        'model': selectedModelOption,
        'motor': motor,
        'year': year,
        'price': price,
        'hpOriginal': hpOriginal,
        'hpAfterTuning': hpAfterTuning,
        'hpDifference': hpDifference,
        'torqueOriginal': torqueOriginal,
        'torqueAfterTuning': torqueAfterTuning,
        'torqueDifference': torqueDifference,
      })
      alert("Saved")
      window.location.reload();
    } catch (error) {
      console.log(error);
      alert(error?.response?.data?.validationErrors)
    }
  }


  const signOut = async () => {
    await logout();
    navigate('/linkpage');
  }

  return (
    <>
      <main>
        <NavbarTop/>
        <div className="main__content">
          <div className="settings">
            <form className="settings__form" action="">
              <div className="settings__content">
                <span className="settings__headline">Tuning Setting</span>
                <div className="settings__wrapper">
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={selectOptions}
                    placeholder={"Chose Brand"}
                    onChange={newValue => {
                      setSelectedOption(newValue?.value);
                      getModelsByBrandName(newValue?.value)
                        .then(value => {
                          let modelsMap = [];
                          value?.data?.data.forEach(item => {
                            modelsMap.push({value: item?.model, label: item?.model})
                          })
                          setSelectModelOptions(modelsMap)
                        })
                    }}
                  />
                  <Select
                    className="react-select-container"
                    options={selectModelOptions}
                    classNamePrefix="react-select"
                    placeholder={"Chose Model"}
                    onChange={newValue => {
                      setSelectedModelOption(newValue?.value);
                    }}
                  />
                  <div>
                    <input
                      onChange={e => setMotor(e.target.value)}
                      value={motor}
                      style={{width: "100%"}}
                      className="settings__input"
                      type="text"
                      placeholder="Motor"
                      required
                    />
                  </div>
                  <div>
                    <input
                      onChange={e => setYear(e.target.value)}
                      value={year}
                      style={{width: "100%"}}
                      className="settings__input"
                      type="text"
                      placeholder="Year"
                      required
                    />
                  </div>
                  <div>
                    <input
                      onChange={e => setPrice(e.target.value)}
                      style={{width: "100%"}}
                      className="settings__input"
                      type="text"
                      placeholder="Price"
                    />
                  </div>
                </div>
              </div>
              <div className="settings__content">
          <span className="settings__headline">
            Tuning Calculate ( Power HP )
          </span>
                <div className="settings__wrapper">
                  <input
                    onChange={e => setHpOriginal(e.target.value)}
                    style={{width: "100%"}}
                    className="settings__input"
                    type="text"
                    placeholder="Orginal"
                  />
                  <input
                    onChange={e => setHpAfterTuning(e.target.value)}
                    style={{width: "100%"}}
                    className="settings__input"
                    type="text"
                    placeholder="After Tuning"
                  />
                  <input
                    onChange={e => setHpDifference(e.target.value)}
                    style={{width: "100%"}}
                    className="settings__input"
                    type="text"
                    placeholder="Difference"
                  />
                </div>
              </div>
              <div className="settings__content">
          <span className="settings__headline">
            Tuning Calculate ( Torque Nm )
          </span>
                <div className="settings__wrapper">
                  <input
                    onChange={e => setTorqueOriginal(e.target.value)}
                    className="settings__input"
                    type="text"
                    placeholder="Orginal"
                  />
                  <input
                    onChange={e => setTorqueAfterTuning(e.target.value)}
                    className="settings__input"
                    type="text"
                    placeholder="After Tuning"
                  />
                  <input
                    onChange={e => setTorqueDifference(e.target.value)}
                    className="settings__input"
                    type="text"
                    placeholder="Difference"
                  />
                  <button onClick={onSubmit} style={{width: "100%"}} type="submit" className="btn btn-primary">
                    Save It
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="choose__logo">
            <form className="choose__logo-form" action="">
              <span className="choose__logo-headline"></span>
              <div className="choose__logo-wrapper">
                <div className="choose__logo-radio">
                  <img src="/assets/images/volvo alt.png" alt="volvo"/>
                  <input name="choose-logo" type="radio" defaultValue="volvo"/>
                </div>
                <div className="choose__logo-radio">
                  <img src="/assets/images/volkswagen.png" alt="volkswagen"/>
                  <input name="choose-logo" type="radio" defaultValue="volkswagen"/>
                </div>
                <div className="choose__logo-radio">
                  <img src="/assets/images/toyota.png" alt="toyota"/>
                  <input name="choose-logo" type="radio" defaultValue="toyota"/>
                </div>
                <div className="choose__logo-radio">
                  <img src="/assets/images/tesla.png" alt="tesla"/>
                  <input name="choose-logo" type="radio" defaultValue="tesla"/>
                </div>
                <div className="choose__logo-radio">
                  <img src="/assets/images/suzuki.png" alt="suzuki"/>
                  <input name="choose-logo" type="radio" defaultValue="suzuki"/>
                </div>
                <div className="choose__logo-radio">
                  <img src="/assets/images/skoda.png" alt="skoda"/>
                  <input name="choose-logo" type="radio" defaultValue="skoda"/>
                </div>
                <div className="choose__logo-radio">
                  <img src="/assets/images/seat.png" alt="seat"/>
                  <input name="choose-logo" type="radio" defaultValue="seat"/>
                </div>
                <div className="choose__logo-radio">
                  <img src="/assets/images/rolls royce.png" alt="rolls royce"/>
                  <input name="choose-logo" type="radio" defaultValue="rollsroyce"/>
                </div>
                <div className="choose__logo-radio">
                  <img src="/assets/images/renault.png" alt="renault"/>
                  <input name="choose-logo" type="radio" defaultValue="renault"/>
                </div>
                <div className="choose__logo-radio">
                  <img src="/assets/images/ram alt.png" alt="ram alt"/>
                  <input name="choose-logo" type="radio" defaultValue="ramalt"/>
                </div>
                <div className="choose__logo-radio">
                  <img src="/assets/images/porsche.png" alt="porsche"/>
                  <input name="choose-logo" type="radio" defaultValue="porsche"/>
                </div>
                <div className="choose__logo-radio">
                  <img src="/assets/images/peugeot.png" alt="peugeot"/>
                  <input name="choose-logo" type="radio" defaultValue="peugeot"/>
                </div>
                <div className="choose__logo-radio">
                  <img src="/assets/images/opel.png" alt="opel"/>
                  <input name="choose-logo" type="radio" defaultValue="opel"/>
                </div>
                <div className="choose__logo-radio">
                  <img src="../assets/images/mitsubishi.png" alt="mitsubishi"/>
                  <input name="choose-logo" type="radio" defaultValue="mitsubishi"/>
                </div>
                <div className="choose__logo-radio">
                  <img src="/assets/images/mini.png" alt="mini"/>
                  <input name="choose-logo" type="radio" defaultValue="mini"/>
                </div>
                <div className="choose__logo-radio">
                  <img
                    src="/assets/images/mercedes benz alt.png"
                    alt="mercedes benz"
                  />
                  <input
                    name="choose-logo"
                    type="radio"
                    defaultValue="mercedesbenz"
                  />
                </div>
                <div className="choose__logo-radio">
                  <img src="/assets/images/mclaren alt.png" alt="mclaren"/>
                  <input name="choose-logo" type="radio" defaultValue="mclaren"/>
                </div>
                <div className="choose__logo-radio">
                  <img src="/assets/images/mazda.png" alt="mazda"/>
                  <input name="choose-logo" type="radio" defaultValue="mazda"/>
                </div>
                <div className="choose__logo-radio">
                  <img src="/assets/images/maybach.png" alt="maybach"/>
                  <input name="choose-logo" type="radio" defaultValue="maybach"/>
                </div>
                <div className="choose__logo-radio">
                  <img src="/assets/images/maserati.png" alt="maserati"/>
                  <input name="choose-logo" type="radio" defaultValue="maserati"/>
                </div>
                <div className="choose__logo-radio">
                  <img src="/assets/images/lexus.png" alt="lexus"/>
                  <input name="choose-logo" type="radio" defaultValue="lexus"/>
                </div>
                <div className="choose__logo-radio">
                  <img src="/assets/images/land rover.png" alt="land rover"/>
                  <input name="choose-logo" type="radio" defaultValue="landrover"/>
                </div>
                <div className="choose__logo-radio">
                  <img src="/assets/images/lamborghini alt.png" alt="lamborghini"/>
                  <input name="choose-logo" type="radio" defaultValue="lamborghini"/>
                </div>
                <div className="choose__logo-radio">
                  <img src="/assets/images/jeep alt.png" alt="jeep"/>
                  <input name="choose-logo" type="radio" defaultValue="jeep"/>
                </div>
                <div className="choose__logo-radio">
                  <img src="/assets/images/jaguar.png" alt="jaguar"/>
                  <input name="choose-logo" type="radio" defaultValue="jaguar"/>
                </div>
                <div className="choose__logo-radio">
                  <img src="/assets/images/hummer.png" alt="hummer"/>
                  <input name="choose-logo" type="radio" defaultValue="hummer"/>
                </div>
                <div className="choose__logo-radio">
                  <img src="/assets/images/honda.png" alt="honda"/>
                  <input name="choose-logo" type="radio" defaultValue="honda"/>
                </div>
                <div className="choose__logo-radio">
                  <img src="/assets/images/ford.png" alt="ford"/>
                  <input name="choose-logo" type="radio" defaultValue="ford"/>
                </div>
                <div className="choose__logo-radio">
                  <img src="/assets/images/ferrari.png" alt="ferrari"/>
                  <input name="choose-logo" type="radio" defaultValue="ferrari"/>
                </div>
                <div className="choose__logo-radio">
                  <img src="/assets/images/citroen.png" alt="citroen"/>
                  <input name="choose-logo" type="radio" defaultValue="citroen"/>
                </div>
                <div className="choose__logo-radio">
                  <img src="/assets/images/chevrolet.png" alt="chevrolet"/>
                  <input name="choose-logo" type="radio" defaultValue="chevrolet"/>
                </div>
                <div className="choose__logo-radio">
                  <img src="/assets/images/bugatti.png" alt="bugatti"/>
                  <input name="choose-logo" type="radio" defaultValue="bugatti"/>
                </div>
                <div className="choose__logo-radio">
                  <img src="/assets/images/bmw.png" alt="bmw"/>
                  <input name="choose-logo" type="radio" defaultValue="bmw"/>
                </div>
                <div className="choose__logo-radio">
                  <img src="/assets/images/bentley.png" alt="bentley"/>
                  <input name="choose-logo" type="radio" defaultValue="bentley"/>
                </div>
                <div className="choose__logo-radio">
                  <img src="/assets/images/audi.png" alt="audi"/>
                  <input name="choose-logo" type="radio" defaultValue="audi"/>
                </div>
                <div className="choose__logo-radio">
                  <img
                    src="/assets/images/aston martin alt.png"
                    alt="aston martin"
                  />
                  <input name="choose-logo" type="radio" defaultValue="astonmartin"/>
                </div>
                <div className="choose__logo-radio">
                  <img src="/assets/images/alfa romeo alt.png" alt="alfa romeo"/>
                  <input name="choose-logo" type="radio" defaultValue="alfaromeo"/>
                </div>
              </div>
            </form>
          </div>
        </div>
      </main>

    </>
  )
}

export default Home
