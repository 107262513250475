import * as React from "react";
import logo from '../assets/images/logo.png'
import BrandIcon from "./icons/brand-icon";
import AboutUsIcon from "./icons/aboutus-icon";
import UserIcon from "./icons/user-icon";
import LogoutIcon from "./icons/logout-icon";
import TuningModIcon from "./icons/tuning-mod-icon";
import WebSettingsIcon from "./icons/websettings-icon";

export default function Sidebar(props) {
  return (
    <nav className="navbar navbar-expand-xl">
      <div className="container">
        <a className="navbar-brand" href="/">
          <img src={logo} width={182} height={38} alt="logo"/>
        </a>
        <div
          className="offcanvas offcanvas-start"
          tabIndex={-1}
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
        >
          <div className="offcanvas-header">
            <a href="/">
              <img
                src={logo}
                width={182}
                height={38}
                alt="logo"
              />
            </a>
            <button
              type="button"
              className="btn"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              <img
                src="/assets/images/close.png"
                width={24}
                height={24}
                alt="close"
              />
            </button>
          </div>
          <div className="offcanvas-body">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className={`nav-link ${window.location.pathname === "/" ? 'active' : ''}`} aria-current="page" href="/">
                  <div className="nav-link-icon">
                    <TuningModIcon/>
                  </div>
                  Tuning Mod
                </a>
              </li>

              <li className="nav-item">
                <a className={`nav-link ${window.location.pathname === "/tuning-setting" ? 'active' : ''}`} aria-current="page" href="/tuning-setting">
                  <div className="nav-link-icon">
                    <WebSettingsIcon/>
                  </div>
                  Tuning Settings
                </a>
              </li>

              <li className="nav-item">
                <a className={`nav-link ${window.location.pathname === "/store" ? 'active' : ''}`} aria-current="page" href="/store">
                <div className="nav-link-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M6.88 18.15V16.08"
                        stroke="#7C7C7C"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                      <path
                        d="M12 18.15V14.01"
                        stroke="#7C7C7C"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                      <path
                        d="M17.12 18.1501V11.9301"
                        stroke="#7C7C7C"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                      <path
                        d="M17.12 5.84998L16.66 6.38998C14.11 9.36998 10.69 11.48 6.88 12.43"
                        stroke="#7C7C7C"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                      <path
                        d="M14.1899 5.84998H17.1199V8.76998"
                        stroke="#7C7C7C"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                        stroke="#7C7C7C"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  Store Setting
                </a>
              </li>

              <li className="nav-item">
                <a className={`nav-link ${window.location.pathname === "/brands" ? 'active' : ''}`} href="/brands">
                  <div className="nav-link-icon">
                    <BrandIcon/>
                  </div>
                  Brands
                </a>
              </li>
              <li className="nav-item">
                <a className={`nav-link ${window.location.pathname === "/models" ? 'active' : ''}`} href="/models">
                  <div className="nav-link-icon">
                    <BrandIcon/>
                  </div>
                  Models
                </a>
              </li>
              <li className="nav-item">
                <a className={`nav-link ${window.location.pathname === "/service" ? 'active' : ''}`} href="/service">
                  <div className="nav-link-icon">
                    <BrandIcon/>
                  </div>
                  Services
                </a>
              </li>
              <li className="nav-item">
                <a className={`nav-link ${window.location.pathname === "/academy" ? 'active' : ''}`} href="/academy">
                  <div className="nav-link-icon">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                    >
                      <path
                          d="M3.17004 7.43994L12 12.5499L20.77 7.46994"
                          stroke="#7C7C7C"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                      />
                      <path
                          d="M12 21.61V12.54"
                          stroke="#7C7C7C"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                      />
                      <path
                          d="M9.93001 2.48004L4.59001 5.44004C3.38001 6.11004 2.39001 7.79004 2.39001 9.17004V14.82C2.39001 16.2 3.38001 17.88 4.59001 18.55L9.93001 21.52C11.07 22.15 12.94 22.15 14.08 21.52L19.42 18.55C20.63 17.88 21.62 16.2 21.62 14.82V9.17004C21.62 7.79004 20.63 6.11004 19.42 5.44004L14.08 2.47004C12.93 1.84004 11.07 1.84004 9.93001 2.48004Z"
                          stroke="#7C7C7C"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  Academy
                </a>
              </li>
              <li className="nav-item">
                <a className={`nav-link ${window.location.pathname === "/about" ? 'active' : ''}`} href="/about">
                  <div className="nav-link-icon">
                    <AboutUsIcon />
                  </div>
                  About
                </a>
              </li>
              <li className="nav-item">
                <a className={`nav-link ${window.location.pathname === "/dealer" ? 'active' : ''}`} href="/dealer">
                  <div className="nav-link-icon">
                    <TuningModIcon/>
                  </div>
                  Dealers
                </a>
              </li>
              <li className="nav-item">
                <a className={`nav-link ${window.location.pathname === "/web-settings" ? 'active' : ''}`} href="/web-settings">
                  <div className="nav-link-icon">
                    <WebSettingsIcon />
                  </div>
                  Web Setting
                </a>
              </li>
              <li className="nav-item">
                <a className={`nav-link ${window.location.pathname === "/service-faq" ? 'active' : ''}`} href="/service-faq">
                  <div className="nav-link-icon">
                    <WebSettingsIcon />
                  </div>
                  Service FAQs
                </a>
              </li>

            </ul>
            <ul className="navbar-nav user">
              <li className="nav-item">
                <a className="nav-link" href="/">
                  <div className="nav-link-icon">
                    <UserIcon/>
                  </div>
                  User
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/">
                  <div className="nav-link-icon">
                    <LogoutIcon/>
                  </div>
                  Logout
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>

  )
}