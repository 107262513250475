import React, {useEffect, useState} from 'react';
import NavbarTop from "./NavbarTop";
import {createBrand, createServiceFAQs, getBrands, getServiceFAQs} from "../api/axios";
import DataTable, {Alignment} from 'react-data-table-component';

const ServiceFaq = () => {
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    getServiceFAQs().then(value => setFaqs(value?.data?.data))
  }, [0]);

  async function onSubmit(e) {
    try {
      await createServiceFAQs(title, text);
      alert("Saved")
      window.location.reload();
    } catch (error) {
      console.log(error);
      alert(error?.response?.data?.validationErrors)
    }
  }

  const columns = [
    {
      name: 'Title',
      selector: row => row?.title?.length >= 50 ? row.title.slice(0, 50) + "..." : row.title,
    },
    {
      name: 'Text',
      selector: row => row?.text?.length >= 200 ? row.text.slice(0, 200) + "..." : row.text,
    },
    {
      name: 'Update',
      selector: row => <a href={`/service-faq/${row.id}`} className="btn-update">Update</a>,
    },
  ]
  const ExpandedComponent = ({data}) => (
    <div style={{paddingLeft: "30px", display: "flex", flexDirection: "column", paddingBottom: "25px", borderBottom: "1px solid black"}}>
      <span>Title: <b>{data.title}</b></span>
      <span>Text: <b>{data.text}</b></span>
      <span>Created At: <b>{new Date(data.createTimestamp).toLocaleString()}</b></span>
    </div>
  );
  return (<>
    <NavbarTop/>
    <div className="container" style={{marginTop: "20px"}}>
      <div className="brands-create">
        <div className="settings__content">
          <span className="settings__headline">
            Add New Service FAQ
          </span>
          <div className="settings__wrapper website__wrapper">
            <input
              onChange={e => setTitle(e.target.value)}
              className="settings__input"
              type="text"
              placeholder="Title"
              required
            />
            <textarea
              style={{height: "5rem"}}
              maxLength={8000}
              onChange={e => setText(e.target.value)}
              className="settings__input"
              placeholder="Content"
              required
            />
            <button onClick={onSubmit}
                    type="submit"
                    className="btn btn-primary btn-brand-save"
                    disabled={title.trim() === "" || text.trim() === ""}>
              Save New Service FAQ
            </button>
          </div>
        </div>
      </div>
      <div className="service-faq-list" style={{border: "1px solid white"}}>
        <DataTable
          title="Service FAQs"
          highlightOnHover={true}
          columns={columns}
          data={faqs}
          striped
          expandableRows={true}
          expandableRowsComponent={ExpandedComponent}
          theme="dark"
          pagination={false}
          noDataComponent="No Results"
        />
      </div>
    </div>
  </>)

}
export default ServiceFaq;