import Login from './components/Login';
import Home from './components/Home';
import Layout from './components/Layout';
import Missing from './components/Missing';
import Unauthorized from './components/Unauthorized';
import RequireAuth from './components/RequireAuth';
import PersistLogin from './components/PersistLogin';
import {Route, Routes} from 'react-router-dom';
import Sidebar from "./components/Sidebar";
import Header2 from "./components/Header2";
import logo from '../src/asferrarilogo.png'
import useAuth from "./hooks/useAuth";
import Brands from "./components/Brands";
import Models from "./components/Models";
import BrandsUpdate from "./components/BrandsUpdate";
import ModelsUpdate from "./components/ModelsUpdate";
import WebSettings from "./components/WebSettings";
import ServiceFaq from "./components/ServiceFaq";
import ServiceFaqUpdate from "./components/ServiceFaqUpdate";
import Store from "./components/Store";
import StoreUpdate from "./components/StoreUpdate";
import Dealer from "./components/Dealer";
import TuningSettings from "./components/TuningSettings";
import TuningUpdate from "./components/TuningUpdate";
import Academy from "./components/Academy";
import AcademyUpdate from "./components/AcademyUpdate";
import About from "./components/About";
import Service from "./components/Service";
import ServiceUpdate from "./components/ServiceUpdate";


const ROLES = {
  'User': 2001,
  'Editor': 1984,
  'Admin': 5150
}

function App() {
  const {auth} = useAuth();

  return (
    <>
      <div className="wrapper">
        {auth?.accessToken ?
          <Sidebar logoSrc={logo}/> : <Header2 logoSrc={logo}/>}

        <Routes>
          <Route path="/" element={<Layout/>}>
            {/* public routes */}
            <Route path="login" element={<Login/>}/>
            <Route path="unauthorized" element={<Unauthorized/>}/>

            {/* we want to protect these routes */}
            <Route element={<PersistLogin/>}>
              <Route element={<RequireAuth allowedRoles={[ROLES.User]}/>}>
                <Route path="/" element={<Home/>}/>
                <Route path="/tuning-setting" element={<TuningSettings/>}/>
                <Route path="/tuning/:id" element={<TuningUpdate/>}/>
                <Route path="/brands" element={<Brands/>}/>
                <Route path="/brands/:id" element={<BrandsUpdate/>}/>
                <Route path="/models" element={<Models/>}/>
                <Route path="/models/:id" element={<ModelsUpdate/>}/>
                <Route path="/web-settings" element={<WebSettings/>}/>
                <Route path="/service-faq" element={<ServiceFaq/>}/>
                <Route path="/service-faq/:id" element={<ServiceFaqUpdate/>}/>
                <Route path="/store" element={<Store/>}/>
                <Route path="/store/:id" element={<StoreUpdate/>}/>
                <Route path="/dealer" element={<Dealer/>}/>
                {/* <Route path="/store/:id" element={<StoreUpdate/>}/> */}
                <Route path="/academy" element={<Academy/>}/>
                <Route path="/academy/:id" element={<AcademyUpdate/>}/>
                <Route path="/about" element={<About/>}/>
                <Route path="/service" element={<Service/>}/>
                <Route path="/service/:id" element={<ServiceUpdate/>}/>




              </Route>
            </Route>

            {/* catch all */}
            <Route path="*" element={<Missing/>}/>
          </Route>
        </Routes>
      </div>
    </>
  );
}

export default App;