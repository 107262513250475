import React, {useEffect, useState} from 'react';
import NavbarTop from "./NavbarTop";
import {createAbout, getAbout, getPosts} from "../api/axios";

const Academy = () => {
  const [faqs, setFaqs] = useState([]);

  const [mainTitle, setMainTitle] = useState("");
  const [mainDesc, setMainDesc] = useState("");
  const [title1, setTitle1] = useState("");
  const [text1, setText1] = useState("");

  const [award1Number, setAward1Number] = useState("");
  const [award1Title, setAward1Title] = useState("");
  const [award1Text, setAward1Text] = useState("");

  const [award2Number, setAward2Number] = useState("");
  const [award2Title, setAward2Title] = useState("");
  const [award2Text, setAward2Text] = useState("");

  const [award3Number, setAward3Number] = useState("");
  const [award3Title, setAward3Title] = useState("");
  const [award3Text, setAward3Text] = useState("");

  const [award4Number, setAward4Number] = useState("");
  const [award4Title, setAward4Title] = useState("");
  const [award4Text, setAward4Text] = useState("");

  const [wordOfFounder, setWordOfFounder] = useState("");

  useEffect(() => {
    getAbout().then(value => {
      setMainTitle(value?.data?.data?.mainTitle);
      setMainDesc(value?.data?.data?.mainDesc);
      setTitle1(value?.data?.data?.title1);
      setText1(value?.data?.data?.text1);

      setAward1Title(value?.data?.data?.award1Title);
      setAward1Number(value?.data?.data?.award1Number);
      setAward1Text(value?.data?.data?.award1Text);

      setAward2Title(value?.data?.data?.award2Title);
      setAward2Number(value?.data?.data?.award2Number);
      setAward2Text(value?.data?.data?.award2Text);

      setAward3Title(value?.data?.data?.award3Title);
      setAward3Number(value?.data?.data?.award3Number);
      setAward3Text(value?.data?.data?.award3Text);

      setAward4Title(value?.data?.data?.award4Title);
      setAward4Number(value?.data?.data?.award4Number);
      setAward4Text(value?.data?.data?.award4Text);

      setWordOfFounder(value?.data?.data?.wordOfFounder);
    })
  }, [0]);

  useEffect(() => {
    getPosts().then(value => setFaqs(value?.data?.data))
  }, [0]);


  async function onSubmit(e) {
    try {
      let state = {
        mainTitle: mainTitle,
        mainDesc: mainDesc,
        title1: title1,
        text1: text1,
        award1Number: award1Number,
        award1Title: award1Title,
        award1Text: award1Text,

        award2Number: award2Number,
        award2Title: award2Title,
        award2Text: award2Text,

        award3Number: award3Number,
        award3Title: award3Title,
        award3Text: award3Text,

        award4Number: award4Number,
        award4Title: award4Title,
        award4Text: award4Text,

        wordOfFounder: wordOfFounder,
      }

      await createAbout(state);
      alert("Saved")
      window.location.reload();
    } catch (error) {
      console.log(error);
      alert(error?.response?.data?.validationErrors)
    }
  }

  return (<>
    <NavbarTop/>
    <div className="container" style={{marginTop: "20px"}}>
      <div className="brands-create">
        <div className="settings__content">
          <span className="settings__headline">
            Update About
          </span>
          <div className="settings__wrapper website__wrapper">
            <input
              onChange={e => setMainTitle(e.target.value)}
              className="settings__input"
              type="text"
              placeholder="Main Title"
              value={mainTitle}
              required
            />
            <input
              onChange={e => setMainDesc(e.target.value)}
              className="settings__input"
              type="text"
              placeholder="Main Description"
              value={mainDesc}
              required
            />
            <input
              onChange={e => setTitle1(e.target.value)}
              className="settings__input"
              type="text"
              placeholder="What is Asferrari Title"
              value={title1}
              required
            />
            <input
              onChange={e => setText1(e.target.value)}
              className="settings__input"
              type="text"
              value={text1}
              placeholder="What is Asferrari Title Description"
              required
            />
            <span className="settings__headline mb-0">
                            Awards Section
                          </span>
            <div className="d-flex flex-row gap-5">
              <div className="d-flex flex-column w-50" style={{gap: "2.5rem"}}>

                <input
                  onChange={e => setAward1Title(e.target.value)}
                  className="settings__input"
                  type="text"
                  value={award1Title}
                  placeholder="First Award Title"
                  required
                />
                <input
                  onChange={e => setAward1Number(e.target.value)}
                  className="settings__input"
                  type="text"
                  value={award1Number}
                  placeholder="First Award Count"
                  required
                />
                <input
                  onChange={e => setAward1Text(e.target.value)}
                  className="settings__input"
                  type="text"
                  value={award1Text}
                  placeholder="First Award Text"
                  required
                />
              </div>

              <div className="d-flex flex-column w-50" style={{gap: "2.5rem"}}>
                <input
                  onChange={e => setAward2Title(e.target.value)}
                  className="settings__input"
                  type="text"
                  value={award2Title}
                  placeholder="Second Award Title"
                  required
                />
                <input
                  onChange={e => setAward2Number(e.target.value)}
                  className="settings__input"
                  type="text"
                  value={award2Number}
                  placeholder="Second Award Count"
                  required
                />
                <input
                  onChange={e => setAward2Text(e.target.value)}
                  className="settings__input"
                  type="text"
                  value={award2Text}
                  placeholder="Second Award Text"
                  required
                />
              </div>

              <div className="d-flex flex-column w-50" style={{gap: "2.5rem"}}>
                <input
                  onChange={e => setAward3Title(e.target.value)}
                  className="settings__input"
                  type="text"
                  value={award3Title}
                  placeholder="Third Award Title"
                  required
                />
                <input
                  onChange={e => setAward3Number(e.target.value)}
                  className="settings__input"
                  type="text"
                  value={award3Number}
                  placeholder="Third Award Count"
                  required
                />
                <input
                  onChange={e => setAward3Text(e.target.value)}
                  className="settings__input"
                  type="text"
                  value={award3Text}
                  placeholder="Third Award Text"
                  required
                />
              </div>

              <div className="d-flex flex-column w-50" style={{gap: "2.5rem"}}>
                <input
                  onChange={e => setAward4Title(e.target.value)}
                  className="settings__input"
                  type="text"
                  value={award4Title}
                  placeholder="Fourth Award Title"
                  required
                />
                <input
                  onChange={e => setAward4Number(e.target.value)}
                  className="settings__input"
                  type="text"
                  value={award4Number}
                  placeholder="Fourth Award Count"
                  required
                />
                <input
                  onChange={e => setAward4Text(e.target.value)}
                  className="settings__input"
                  type="text"
                  value={award4Text}
                  placeholder="Fourth Award Text"
                  required
                />
              </div>

            </div>

            <textarea
              style={{height: "25rem"}}
              maxLength={8000}
              onChange={e => setWordOfFounder(e.target.value)}
              className="settings__input"
              value={wordOfFounder}
              placeholder="Word Of Founder"
              required
            />
            <div className="d-flex flex-row gap-5">


            </div>
            <button onClick={onSubmit}
                    type="submit"
                    className="btn btn-primary btn-brand-save"
            >
              Save About
            </button>
          </div>
        </div>
      </div>
    </div>
  </>)

}
export default Academy;