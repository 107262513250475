import React, {useEffect, useRef, useState} from 'react';
import NavbarTop from "./NavbarTop";
import {createBrand, createProduct, getBrands, getWebSettings, updateSlider, updateWebSettings} from "../api/axios";

const WebSettings = () => {
  const [instagramUrl, setInstagramUrl] = useState("");
  const [whatsappNo, setWhatsappNo] = useState("");
  const [metaUrl, setMetaUrl] = useState("");
  const [tiktokUrl, setTiktokUrl] = useState("");
  const [xUrl, setXUrl] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [footerFirstD, setFooterFirstD] = useState("");
  const [address, setAddress] = useState("");
  const [image, setImage] = useState(null);
  const [imagePrev, setImagePrev] = useState(null);
  const [imgUrl, setImgUrl] = useState(null);
  const inputFile = useRef(null);

  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  useEffect(() => {
    getWebSettings().then(value => {
      setInstagramUrl(value?.data?.data?.instagramUrl)
      setWhatsappNo(value?.data?.data?.whatsappNo)
      setMetaUrl(value?.data?.data?.metaUrl)
      setTiktokUrl(value?.data?.data?.tiktokUrl)
      setXUrl(value?.data?.data?.twitterUrl)
      setPhoneNumber(value?.data?.data?.phoneNumber)
      setFooterFirstD(value?.data?.data?.footerFirstD)
      setAddress(value?.data?.data?.address)
      setImagePrev(value?.data?.data?.sliderImage);
      setImgUrl(`data:image/jpeg;base64,${value?.data?.data?.sliderImage}`);
    })
  }, [0]);

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(event.target.files[0]);
      setImagePrev(URL.createObjectURL(event.target.files[0]));
      setImgUrl(URL.createObjectURL(event.target.files[0]));
    }
  }

  async function onSubmit(e) {
    try {
      await updateWebSettings({
        'instagramUrl': instagramUrl,
        'whatsappNo': whatsappNo,
        'metaUrl': metaUrl,
        'tiktokUrl': tiktokUrl,
        'twitterUrl': xUrl,
        'phoneNumber': phoneNumber,
        'footerFirstD': footerFirstD,
        'address': address,
      })
      alert("Saved")
      window.location.reload();
    } catch (error) {
      console.log(error);
      alert(error?.response?.data?.validationErrors)
    }
  }

  async function onSubmitSlider(e) {
    try {
      let formData = new FormData();
      formData.append('file', image);
      await updateSlider(formData);
      alert("Saved")
      window.location.reload();
    } catch (error) {
      console.log(error);
      alert(error?.response?.data?.validationErrors)
    }
  }

  return (<>
    <NavbarTop/>
    <div className="container" style={{marginTop: "20px"}}>
      <div className="brands-create">
        <div className="settings__content">
          <span className="settings__headline">
            Update Website Settings
          </span>
          <div className="settings__wrapper website__wrapper">
            <input
              onChange={e => setInstagramUrl(e.target.value)}
              value={instagramUrl}
              className="settings__input"
              type="text"
              placeholder="Instagram URL"
              required
            />
            <input
              onChange={e => setWhatsappNo(e.target.value)}
              value={whatsappNo}
              className="settings__input"
              type="text"
              placeholder="Whatsapp No"
              required
            />
            <input
              onChange={e => setMetaUrl(e.target.value)}
              value={metaUrl}
              className="settings__input"
              type="text"
              placeholder="Facebook / Meta URL"
              required
            />
            <input
              onChange={e => setTiktokUrl(e.target.value)}
              value={tiktokUrl}
              className="settings__input"
              type="text"
              placeholder="Tiktok URL"
              required
            />
            <input
              onChange={e => setXUrl(e.target.value)}
              value={xUrl}
              className="settings__input"
              type="text"
              placeholder="X / Twitter URL"
              required
            />
            <input
              onChange={e => setPhoneNumber(e.target.value)}
              value={phoneNumber}
              className="settings__input"
              type="text"
              placeholder="Phone Number"
              required
            />
            <input
              onChange={e => setFooterFirstD(e.target.value)}
              value={footerFirstD}
              className="settings__input"
              type="text"
              placeholder="Footer Description"
              required
            />
            <input
              onChange={e => setAddress(e.target.value)}
              value={address}
              className="settings__input"
              type="text"
              placeholder="Address"
              required
            />
            <button onClick={onSubmit} type="submit" className="btn btn-primary">
              Update Web Settings
            </button>
          </div>

          <br />
          <br />
          <span className="settings__headline">
            Update Slider
          </span>

          <label htmlFor="images" className="drop-container" id="dropcontainer">
            <button className="upload-image-button" onClick={onButtonClick}>Upload Your Image</button>
          </label>
          <input  onChange={onImageChange}  className="align-rigfht" ref={inputFile} style={{display: 'none'}} type='file' id='file'/>

<center>
  <div className="d-fledx">
    {imagePrev !== null ? <img className="image-size-50" alt="preview image" src={imgUrl}/> : ""}
  </div>
  <br />
  <button onClick={onSubmitSlider} type="submit" className="btn btn-primary">
    Update Slider
  </button>

</center>


        </div>
      </div>
    </div>
  </>)

}
export default WebSettings;