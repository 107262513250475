import React, {useEffect, useState, useRef} from 'react';
import NavbarTop from "./NavbarTop";
import {
  createBrand,
  createProduct,
  createServiceFAQs, deleteProduct,
  getBrands,
  getModelById, getProductById,
  getProducts,
  getServiceFAQs, updateProduct
} from "../api/axios";
import DataTable, {Alignment} from 'react-data-table-component';
import {useNavigate, useParams} from "react-router-dom";

const Store = () => {
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [price, setPrice] = useState("");
  const [link, setLink] = useState("");
  const [image, setImage] = useState(null);
  const [imagePrev, setImagePrev] = useState(null);
  const [faqs, setFaqs] = useState([]);
  const [imgUrl, setImgUrl] = useState(null);
  const {id} = useParams();
  const inputFile = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    getProducts().then(value => setFaqs(value?.data?.data))
  }, [0]);

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(event.target.files[0]);
      setImagePrev(URL.createObjectURL(event.target.files[0]));
      setImgUrl(URL.createObjectURL(event.target.files[0]));
    }
  }

  async function onSubmitDelete() {
    try {
      await deleteProduct(id);
      alert("Deleted")
      navigate('/store');

    } catch (error) {
      console.log(error);
      alert(error?.response?.data?.validationErrors)
    }
  }

  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  useEffect(() => {
    getProductById(id).then(value => {
      setTitle(value?.data?.data?.title);
      setPrice(value?.data?.data?.price);
      setLink(value?.data?.data?.link);
      setText(value?.data?.data?.description);
      setImagePrev(value?.data?.data?.image);
      setImgUrl(`${value?.data?.data?.image}`);
    })
  }, [0]);

  async function onSubmit(e) {
    try {
      let state = {
        title: title,
        price: price,
        description: text,
        image: imagePrev,
        link: link
      }

      const model = JSON.stringify(state);

      let formData = new FormData();
      formData.append('file', image);
      formData.append('model', model);

      await updateProduct(formData, id);
      alert("Saved")
      window.location.reload();
    } catch (error) {
      console.log(error);
      alert(error?.response?.data?.validationErrors)
    }
  }

  const columns = [
    {
      name: 'Title',
      selector: row => row?.title?.length >= 50 ? row.title.slice(0, 50) + "..." : row.title,
    },
    {
      name: 'Text',
      selector: row => row?.text?.length >= 200 ? row.text.slice(0, 200) + "..." : row.text,
    },
    {
      name: 'Update',
      selector: row => <a href={`/service-faq/${row.id}`} className="btn-update">Update</a>,
    },
  ]
  const ExpandedComponent = ({data}) => (
    <div style={{paddingLeft: "30px", display: "flex", flexDirection: "column", paddingBottom: "25px", borderBottom: "1px solid black"}}>
      <span>Title: <b>{data.title}</b></span>
      <span>Text: <b>{data.text}</b></span>
      <span>Created At: <b>{new Date(data.createTimestamp).toLocaleString()}</b></span>
    </div>
  );
  return (<>
    <NavbarTop/>
    <div className="container" style={{marginTop: "20px"}}>
      <div className="brands-create">
        <div className="settings__content">
          <span className="settings__headline">
            Update Product
          </span>
          <div className="settings__wrapper website__wrapper">
            <input
              value={title}
              onChange={e => setTitle(e.target.value)}
              className="settings__input"
              type="text"
              placeholder="Title"
              required
            />
            <textarea
              value={text}
              style={{height: "5rem"}}
              maxLength={8000}
              onChange={e => setText(e.target.value)}
              className="settings__input"
              placeholder="Content"
              required
            />

            <input
              value={price}
              onChange={e => setPrice(e.target.value)}
              className="settings__input width-50"
              type="text"
              placeholder="Price"
              required
            />
            <input
              value={link}
              onChange={e => setLink(e.target.value)}
              className="settings__input width-50"
              type="text"
              placeholder="Link"
              required
            />

            <div className="d-flex justify-content-between flex-row">
              <label htmlFor="images" className="drop-container" id="dropcontainer">
                <button className="upload-image-button" onClick={onButtonClick}>Add Product Image</button>
              </label>
              <input onChange={onImageChange} className="align-rigfht" ref={inputFile} style={{display: 'none'}} type='file' id='file'/>
              <div className="w-50">
                {imagePrev !== null ? <img style={{maxHeight: "250px"}} alt="preview image" src={"data:image/jpeg;base64," + imagePrev}/> : ""}
              </div>
            </div>
            <button onClick={onSubmit}
                    type="submit"
                    className="btn btn-primary btn-brand-save"
                    disabled={title.trim() === "" || text.trim() === ""}>
              Update Product
            </button>
            <button onClick={onSubmitDelete}
                    type="submit"
                    className="btn btn-primary btn-brand-save bg-red text-white"
            >
              Delete Product
            </button>
          </div>
        </div>
      </div>
    </div>
  </>)

}
export default Store;