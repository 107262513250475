import React, {useEffect, useState} from 'react';
import NavbarTop from "./NavbarTop";
import {getModelById, updateModel} from "../api/axios";
import {useParams} from "react-router-dom";

const ModelsUpdate = () => {
  const [modelName, setModelName] = useState("");
  const [model, setModel] = useState("");
  const {id} = useParams();

  useEffect(() => {
    getModelById(id).then(value => {
      setModel(value?.data?.data?.model);
      setModelName(value?.data?.data?.model);
    })
  }, [0]);

  async function onSubmit(e) {
    try {
      await updateModel(id, modelName);
      alert("Updated")
      window.location.reload();
    } catch (error) {
      console.log(error);
      alert(error?.response?.data?.validationErrors)
    }
  }

  return (
    <>
      <NavbarTop/>
      <div className="container" style={{marginTop: "20px"}}>
        <div className="model-update">
          <div className="settings__content">
          <span className="settings__headline">
            Update Model | <span className="text-highlight">{model}</span>
          </span>
            <div className="settings__wrapper">
              <input
                value={modelName}
                onChange={e => setModelName(e.target.value)}
                className="settings__input"
                type="text"
                placeholder="Model Name"
                required
              />
              <button onClick={onSubmit}
                      type="submit"
                      className="btn btn-primary"
                      disabled={modelName === ""}>
                Update Model Name
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )

}
export default ModelsUpdate;