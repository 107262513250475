import React, { useEffect, useState, useRef } from 'react';
import NavbarTop from "./NavbarTop";

import {
  createDealer,
  deleteDealer,
  getDealers
} from "../api/axios";
import DataTable, { Alignment } from 'react-data-table-component';

const Store = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [faqs, setFaqs] = useState([]);
  const inputFile = useRef(null);

  const mapStyle = [
    { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
    { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
    { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
    {
      featureType: "administrative.locality",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [{ color: "#263c3f" }],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [{ color: "#6b9a76" }],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [{ color: "#38414e" }],
    },
    {
      featureType: "road",
      elementType: "geometry.stroke",
      stylers: [{ color: "#212a37" }],
    },
    {
      featureType: "road",
      elementType: "labels.text.fill",
      stylers: [{ color: "#9ca5b3" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [{ color: "#746855" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [{ color: "#1f2835" }],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [{ color: "#f3d19c" }],
    },
    {
      featureType: "transit",
      elementType: "geometry",
      stylers: [{ color: "#2f3948" }],
    },
    {
      featureType: "transit.station",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [{ color: "#17263c" }],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [{ color: "#515c6d" }],
    },
    {
      featureType: "water",
      elementType: "labels.text.stroke",
      stylers: [{ color: "#17263c" }],
    },
  ];

  const position = { lat: 42.560604004266494, lng: 19.100116524226724 };

  useEffect(() => {
    getDealers().then(value => setFaqs(value?.data?.data))
  }, [0]);


  async function setLatLng(latLng) {
    setLat(latLng.split(",")[0].trim());
    setLng(latLng.split(",")[1].trim());
  }

  async function onSubmit(e) {
    try {
      let state = {
        name: name,
        phone: phone,
        lat: lat,
        lng: lng
      }
      await createDealer(state);
      alert("Saved")
      window.location.reload();
    } catch (error) {
      console.log(error);
      alert(error?.response?.data?.validationErrors)
    }
  }

  async function onSubmitDelete(dealerId) {
    try {
      await deleteDealer(dealerId);
      alert("Deleted")
      window.location.reload();
    } catch (error) {
      console.log(error);
      alert(error?.response?.data?.validationErrors)
    }
  }

  const columns = [
    {
      name: 'Name',
      selector: row => row?.name?.length >= 50 ? row.name.slice(0, 50) + "..." : row.name,
    },
    {
      name: 'Phone',
      selector: row => row?.phone?.length >= 200 ? row.phone.slice(0, 200) + "..." : row.phone,
    },
    {
      name: 'Latitude',
      selector: row => row?.lat?.length >= 200 ? row.lat.slice(0, 200) + "..." : row.lat,
    },
    {
      name: 'Longitude',
      selector: row => row?.lng?.length >= 200 ? row.lng.slice(0, 200) + "..." : row.lng,
    },
    {
      name: 'Delete',
      selector: row => <button onClick={() => onSubmitDelete(row.id)} type="button" className="btn btn-primary btn-brand-save bg-red text-white">
        Delete
      </button>
    },
  ]

  return (<>
    <NavbarTop />
    <div className="container" style={{ marginTop: "20px" }}>
      <div className="brands-create">
        <div className="settings__content">
          <span className="settings__headline">
            Add New Dealer
          </span>
          <div className="settings__wrapper website__wrapper">
            <input
              onChange={e => setName(e.target.value)}
              className="settings__input"
              type="text"
              placeholder="Name"
              required
            />
            <input
              onChange={e => setPhone(e.target.value)}
              className="settings__input"
              type="text"
              placeholder="Phone"
              required
            />
            <div className="d-flex flex-row gap-5">
              <div className="d-flex flex-column w-50" style={{ gap: "2.5rem" }}>
                <input
                  onChange={e => setLatLng(e.target.value)}
                  className="settings__input"
                  type="text"
                  placeholder="Latitude,Longitude"
                  required
                />
              </div>
            </div>
            <button onClick={onSubmit}
              type="submit"
              className="btn btn-primary btn-brand-save"
              disabled={name.trim() === "" || phone.trim() === "" || lat.trim() === "" || lng.trim() === ""}>
              Save New Dealer
            </button>
          </div>
        </div>
      </div>
      <div className="service-faq-list" style={{ border: "5px solid #232323" }}>
        <DataTable
          title="Dealers"
          highlightOnHover={true}
          columns={columns}
          data={faqs}
          striped
          theme="dark"
          pagination={false}
          noDataComponent="No Results"
        />
      </div>
    </div>
  </>)

}
export default Store;