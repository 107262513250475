import React, {useEffect, useState} from 'react';
import NavbarTop from "./NavbarTop";
import {createBrand, createModel, getBrands, getModelsByBrandName, getTunings} from "../api/axios";
import DataTable, {Alignment} from 'react-data-table-component';
import Select from 'react-select'

const TuningSettings = () => {
  const [modelName, setModelName] = useState("");
  const [modelsTableData, setModelsTableData] = useState([]);
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedModelOption, setSelectedModelOption] = useState("");
  const [selectModelOptions, setSelectModelOptions] = useState([]);
  const [selectModelText, setSelectModelText] = useState(null);

  useEffect(async () => {
    let brandsMap = [];
    await getBrands().then(value => {
      value?.data?.data.forEach(item => {
        brandsMap.push({value: item?.brandName, label: item?.brandName})
      })
    })
    setSelectOptions(brandsMap);
  }, [0]);

  const columns = [
    {
      name: 'ID',
      selector: row => row.id,
    },
    {
      name: 'Model Name',
      selector: row => row.model,
    },
    {
      name: 'Update',
      selector: row => <a href={`/tuning/${row.id}`} className="btn-update">Update</a>,
    },
  ]

  return (<>
    <NavbarTop/>
    <div className="container" style={{marginTop: "20px"}}>
      <div className="models-create">
        <div className="settings__content">
          <span className="settings__headline">
            Tuning Settings
          </span>
          <div className="settings__wrapper">
            <Select
              className="react-select-container"
              options={selectOptions}
              placeholder={"Chose Brand"}
              classNamePrefix="react-select"
              onChange={newValue => {
                setSelectedOption(newValue?.value);
                getModelsByBrandName(newValue?.value)
                  .then(value => {
                    let modelsMap = [];
                    value?.data?.data.forEach(item => {
                      modelsMap.push({value: item?.model, label: item?.model})
                    })
                    setSelectModelOptions(modelsMap)
                  })
              }}
            />
            <Select
              className="react-select-container"
              placeholder={"Chose Model"}
              classNamePrefix="react-select"
              options={selectModelOptions}
              onChange={newValue => {
                setSelectModelText(newValue?.value)
                setSelectedModelOption(newValue?.value)
                getTunings(selectedOption, newValue?.value)
                  .then(value => {
                    let modelsMap = [];
                    value?.data?.data.forEach(item => {
                      modelsMap.push({value: item?.model, label: item?.model})
                    })
                    setModelsTableData(value?.data?.data)
                  })
              }}
            />
          </div>
        </div>
      </div>
      {
        selectedModelOption ? (
          <div className="models-list" style={{border: "5px solid #232323"}}>
            <DataTable
              title={`Tuning Results ${selectedOption ? `- ${selectedOption}` : ''} ${selectModelText ? `- ${selectModelText}` : ''} `}
              highlightOnHover={true}
              columns={columns}
              data={modelsTableData}
              striped
              theme="dark"
              pagination={false}
              noDataComponent="No Results"
            />
          </div>
        ) : ''
      }

    </div>
  </>)

}
export default TuningSettings;