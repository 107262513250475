import React, {useEffect, useState} from 'react';
import NavbarTop from "./NavbarTop";
import {createBrand, getBrands} from "../api/axios";
import DataTable, {Alignment} from 'react-data-table-component';

const Brands = () => {
  const [brandName, setBrandName] = useState("");
  const [brands, setBrands] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [msg, setMsg] = useState("");

  useEffect(() => {
    getBrands().then(value => setBrands(value?.data?.data))
  }, [0]);

  async function onSubmit(e) {
    try {
      await createBrand(brandName);
      setMsg("Saved")
      alert("Saved")
      window.location.reload();
    } catch (error) {
      console.log(error);
      setMsg(error?.response?.data?.validationErrors)
      alert(error?.response?.data?.validationErrors)
    }
  }

  const columns = [
    {
      name: 'ID',
      selector: row => row.id,
    },
    {
      name: 'Brand Name',
      selector: row => row.brandName,
    },
    {
      name: 'Total Model',
      selector: row => row.models?.length,
    },
    {
      name: 'Update',
      selector: row => <a href={`/brands/${row.id}`} className="btn-update">Update</a>,
    },
  ]

  return (<>
    <NavbarTop/>
    <div className="container" style={{marginTop: "20px"}}>
      <div className="brands-create">
        <div className="settings__content">
          <span className="settings__headline">
            Add New Brand
          </span>
          <div className="settings__wrapper">
            <input
              onChange={e => setBrandName(e.target.value)}
              className="settings__input"
              type="text"
              placeholder="Brand Name"
              required
            />
            <button onClick={onSubmit}
                    type="submit"
                    className="btn btn-primary btn-brand-save"
                    disabled={brandName === ""}>
              Save New Brand
            </button>
          </div>
        </div>
      </div>
      <div className="brands-list" style={{border: "5px solid #232323"}}>
        <DataTable
          title="Brands"
          highlightOnHover={true}
          columns={columns}
          data={brands}
          striped
          theme="dark"
          pagination={false}
          noDataComponent="No Results"
        />
      </div>
    </div>
  </>)

}
export default Brands;