import React, {useEffect, useState} from 'react';
import NavbarTop from "./NavbarTop";
import {createBrand, createModel, getBrands, getModelsByBrandName} from "../api/axios";
import DataTable, {Alignment} from 'react-data-table-component';
import Select from 'react-select'

const Models = () => {
  const [modelName, setModelName] = useState("");
  const [modelsTableData, setModelsTableData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");

  useEffect(async () => {
    let brandsMap = [];
    await getBrands().then(value => {
      value?.data?.data.forEach(item => {
        brandsMap.push({value: item?.brandName, label: item?.brandName})
      })
    })
    setSelectOptions(brandsMap);
  }, [0]);

  async function onSubmit(e) {
    if (selectedOption.trim() === "") {
      alert("Choose brand")
    } else {
      try {
        await createModel(selectedOption, modelName);
        alert("Saved")
        window.location.reload();
      } catch (error) {
        console.log(error);
        alert(error?.response?.data?.validationErrors)
      }
    }
  }

  const columns = [
    {
      name: 'ID',
      selector: row => row.id,
    },
    {
      name: 'Model Name',
      selector: row => row.model,
    },
    {
      name: 'Update',
      selector: row => <a href={`/models/${row.id}`} className="btn-update">Update</a>,
    },
  ]

  return (<>
    <NavbarTop/>
    <div className="container" style={{marginTop: "20px"}}>
      <div className="models-create">
        <div className="settings__content">
          <span className="settings__headline">
            Add New Model
          </span>
          <div className="settings__wrapper">
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              placeholder={"Chose Brand"}
              options={selectOptions}
              onChange={newValue => {
                setSelectedOption(newValue?.value);
                getModelsByBrandName(newValue?.value)
                  .then(value => {
                    setModelsTableData(value?.data?.data)
                  })
              }}
            />
            <input
              onChange={e => setModelName(e.target.value)}
              className="settings__input"
              type="text"
              placeholder="Model Name"
              required
            />
            <button onClick={onSubmit}
                    type="submit"
                    className="btn btn-primary btn-brand-save"
                    disabled={modelName?.trim() === "" || selectedOption?.trim() === ""}>
              Save New Model
            </button>
          </div>
        </div>
      </div>

      {
        selectedOption ? (
          <div className="models-list" style={{border: "5px solid #232323"}}>
            <DataTable
              title={`Models ${selectedOption ? `(${selectedOption})` : ''} `}
              highlightOnHover={true}
              columns={columns}
              data={modelsTableData}
              striped
              theme="dark"
              pagination={false}
              noDataComponent="No Results"
            />
          </div>
        ) : ''
      }

    </div>
  </>)

}
export default Models;