import React, {useEffect, useState} from 'react';
import NavbarTop from "./NavbarTop";
import {getBrandById, updateBrand} from "../api/axios";
import {useParams} from "react-router-dom";

const BrandsUpdate = () => {
  const [brandName, setBrandName] = useState("");
  const [brand, setBrand] = useState("");
  const {id} = useParams();

  useEffect(() => {
    getBrandById(id).then(value => {
      setBrand(value?.data?.data?.brandName);
      setBrandName(value?.data?.data?.brandName);
    })
  }, [0]);

  async function onSubmit(e) {
    try {
      await updateBrand(id, brandName);
      alert("Updated")
      window.location.reload();
    } catch (error) {
      console.log(error);
      alert(error?.response?.data?.validationErrors)
    }
  }

  return (<>
    <NavbarTop/>
    <div className="container" style={{marginTop: "20px"}}>
      <div className="brands-create">
        <div className="settings__content">
          <span className="settings__headline">
            Update Brand | <span className="text-highlight">{brand}</span>
          </span>
          <div className="settings__wrapper">
            <input
              value={brandName}
              onChange={e => setBrandName(e.target.value)}
              className="settings__input"
              type="text"
              placeholder="Brand Name"
              required
            />
            <button onClick={onSubmit}
                    type="submit"
                    className="btn btn-primary btn-brand-save"
                    disabled={brandName === ""}>
              Update Brand Name
            </button>
          </div>
        </div>
      </div>
    </div>
  </>)

}
export default BrandsUpdate;