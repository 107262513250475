import React, {useEffect, useState} from 'react';
import NavbarTop from "./NavbarTop";
import {getServiceFAQs, updateServiceFAQs} from "../api/axios";
import {useParams} from "react-router-dom";

const ServiceFaqUpdate = () => {
  const {id} = useParams();
  const [title, setTitle] = useState("");
  const [titleBase, setTitleBase] = useState("");
  const [text, setText] = useState("");

  useEffect(() => {
    getServiceFAQs()
      .then(value => {
        value?.data?.data?.forEach(item => {
          if (item?.id === id) {
            setTitleBase(item?.title);
            setTitle(item?.title);
            setText(item?.text);
          }
        })
      })
  }, [0]);

  async function onSubmit(e) {
    try {
      await updateServiceFAQs(title, text, id);
      alert("Updated")
      window.location.reload();
    } catch (error) {
      console.log(error);
      alert(error?.response?.data?.validationErrors)
    }
  }

  return (<>
    <NavbarTop/>
    <div className="container" style={{marginTop: "20px"}}>
      <div className="brands-create">
        <div className="settings__content">
          <span className="settings__headline">
            Update Service FAQ | <span className="text-highlight">{titleBase}</span>
          </span>
          <div className="settings__wrapper website__wrapper">
            <input
              value={title}
              onChange={e => setTitle(e.target.value)}
              className="settings__input"
              type="text"
              placeholder="Title"
              required
            />
            <textarea
              value={text}
              style={{height: "5rem"}}
              maxLength={8000}
              onChange={e => setText(e.target.value)}
              className="settings__input"
              placeholder="Content"
              required
            />
            <button onClick={onSubmit}
                    type="submit"
                    className="btn btn-primary btn-brand-save"
                    disabled={title.trim() === "" || text.trim() === ""}>
              Update Service FAQ
            </button>
          </div>
        </div>
      </div>
    </div>
  </>)

}
export default ServiceFaqUpdate;