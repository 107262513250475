import React, {useEffect, useState} from 'react';
import NavbarTop from "./NavbarTop";
import {getModelById, getModelsByBrandName, getTuning, updateModel, updateTuning} from "../api/axios";
import {useParams} from "react-router-dom";
import Select from "react-select";

const TuningUpdate = () => {
  const [modelName, setModelName] = useState("");
  const [model, setModel] = useState("");
  const {id} = useParams();

  const [brand, setBrand] = useState("");
  const [motor, setMotor] = useState("");
  const [year, setYear] = useState("");
  const [price, setPrice] = useState("");
  const [hpOriginal, setHpOriginal] = useState("");
  const [hpAfterTuning, setHpAfterTuning] = useState("");
  const [hpDifference, setHpDifference] = useState("");
  const [torqueOriginal, setTorqueOriginal] = useState("");
  const [torqueAfterTuning, setTorqueAfterTuning] = useState("");
  const [torqueDifference, setTorqueDifference] = useState("");

  const [selectedOption, setSelectedOption] = useState("");
  const [selectedModelOption, setSelectedModelOption] = useState("");

  const [selectOptions, setSelectOptions] = useState([]);
  const [selectModelOptions, setSelectModelOptions] = useState([]);

  useEffect(() => {
    getTuning(id).then(value => {
      setMotor(value?.data?.data?.motor);
      setYear(value?.data?.data?.year);
      setPrice(value?.data?.data?.price);
      setBrand(value?.data?.data?.brand);
      setModel(value?.data?.data?.model);

      setHpOriginal(value?.data?.data?.hpOriginal);
      setHpAfterTuning(value?.data?.data?.hpAfterTuning);
      setHpDifference(value?.data?.data?.hpDifference);

      setTorqueOriginal(value?.data?.data?.torqueOriginal);
      setTorqueAfterTuning(value?.data?.data?.torqueAfterTuning);
      setTorqueDifference(value?.data?.data?.torqueDifference);
    })
  }, [0]);

  async function onSubmit(e) {
    try {
      let state = {
        motor: motor,
        year: year,
        price: price,
        hpOriginal: Number(hpOriginal),
        hpAfterTuning: hpAfterTuning,
        hpDifference: hpDifference,

        torqueOriginal: torqueOriginal,
        torqueAfterTuning: torqueAfterTuning,
        torqueDifference: torqueDifference
      }
      await updateTuning(id, state);
      alert("Updated")
      window.location.reload();
    } catch (error) {
      console.log(error);
      alert(error?.response?.data?.validationErrors)
    }
  }

  return (
    <>
      <NavbarTop/>
      <div className="main__content">
        <div className="settings">
          <form className="settings__form" action="">
            {/* item */}
            <div className="settings__content">
              <span className="settings__headline">Update Tuning</span>
              <div className="settings__wrapper">
                {/* select */}
                <input
                  onChange={e => setMotor(e.target.value)}
                  value={brand}
                  className="settings__input"
                  style={{cursor:"not-allowed"}}
                  type="text"
                  placeholder="Motor"
                  disabled={true}
                />
                {/* select */}
                <input
                  onChange={e => setMotor(e.target.value)}
                  value={model}
                  className="settings__input"
                  style={{cursor:"not-allowed"}}
                  type="text"
                  placeholder="Motor"
                  disabled={true}
                />
                {/* select */}

                <input
                  onChange={e => setMotor(e.target.value)}
                  value={motor}
                  className="settings__input"
                  type="text"
                  placeholder="Motor"
                  required
                />
                {/* select */}
                <input
                  onChange={e => setYear(e.target.value)}
                  value={year}
                  className="settings__input"
                  type="text"
                  placeholder="Motor"
                  required
                />
                <input
                  value={price}
                  onChange={e => setPrice(e.target.value)}
                  className="settings__input"
                  type="text"
                  placeholder="Price"
                />
              </div>
            </div>
            {/* item */}
            <div className="settings__content">
          <span className="settings__headline">
            Tuning Calculate ( Power HP )
          </span>
              <div className="settings__wrapper">
                {/* input */}
                <div className={"d-flex flex-column"}>
                  <label className='innerLabel'>Original Hp</label>
                  <input
                    onChange={e => setHpOriginal(e.target.value)}
                    className="settings__input"
                    type="text"
                    placeholder="Orginal"
                    value={hpOriginal}
                  />
                </div>
                <div className={"d-flex flex-column"}>
                  <label className='innerLabel'>Hp After Tuning</label>
                  <input
                    onChange={e => setHpAfterTuning(e.target.value)}
                    className="settings__input"
                    type="text"
                    placeholder="After Tuning"
                    value={hpAfterTuning}
                  />
                </div>
                <div className={"d-flex flex-column"}>
                  <label className='innerLabel'>Hp Difference</label>
                  <input
                    onChange={e => setHpDifference(e.target.value)}
                    className="settings__input"
                    type="text"
                    placeholder="Difference"
                    value={hpDifference}
                  />
                </div>
              </div>
            </div>
            <div className="settings__content">
          <span className="settings__headline">
            Tuning Calculate ( Torque Nm )
          </span>
              <div className="settings__wrapper">
                {/* input */}
                <div>
                  <label className='innerLabel'>Torque Original</label>
                  <input
                    onChange={e => setTorqueOriginal(e.target.value)}
                    className="settings__input w-100"
                    type="text"
                    placeholder="Orginal"
                    value={torqueOriginal}
                  />
                </div>
                <div>
                  <label className='innerLabel'>Torque After Tuning</label>
                  <input
                    onChange={e => setTorqueAfterTuning(e.target.value)}
                    className="settings__input w-100"
                    type="text"
                    placeholder="After Tuning"
                    value={torqueAfterTuning}
                  />
                </div>
                <div>
                  <label className='innerLabel'>Torque Difference</label>
                  <input
                    onChange={e => setTorqueDifference(e.target.value)}
                    className="settings__input w-100"
                    type="text"
                    placeholder="Difference"
                    value={torqueDifference}
                  />
                </div>
              </div>
              <button onClick={onSubmit} type="submit" className="btn btn-primary"
                      style={{marginTop: "25px", height: "48px", width: "100%"}}
              >
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )

}
export default TuningUpdate;