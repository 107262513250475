import * as React from "react";
import logo from '../assets/images/logo.png'
export default function Header2(props) {
    return (
        <nav className="navbar navbar-expand-xl no-border">
            <div className="container">
                <a className="navbar-brand" href="/">
                    <img src={logo} width={182} height={38} alt="logo" />
                </a>

            </div>
        </nav>

    )
}